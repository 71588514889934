

export default class UIComponent{

  constructor(htmlTag, idOrClass, appModule = ''){

  	this.e = document.createElement(htmlTag)

  	if (idOrClass === undefined) return
  	if (idOrClass.startsWith('#')) this.e.id = idOrClass.substring(1)
    else this.e.className = idOrClass.substring(1)  
  }


  setHTML(html){
  	this.e.innerHTML = html
  }


  getElement(query){
  	return this.e.querySelector(query)
  }


  getElements(query){
  	return this.e.querySelectorAll(query)
  }

  addElement(htmlTag, innerHTML = ''){
    let newElement = document.createElement(htmlTag)
    newElement.innerHTML = innerHTML
    this.e.appendChild(newElement)
    return newElement
  }
  
}

import UIComponent from './UIComponent.js'
import BlogMod from './BlogMod.js'

import cvPage from './cv.page.html'
import frontPage from './front.page.html'
import projectsPage from './projects.page.html'

// Global utility functions
window.log = (...msg) => { console.log(...msg) }
window.error = (...msg) => { console.error(...msg) }



/****** Application start up code ******/

const appMenu = document.getElementById('app-menu')
appMenu.innerHTML = ` 
	<a class="menu-selected" href="#front">HOME</a> 
	<a href="#blog?list">BLOG</a>
	<a href="#projects">PROJECTS</a>
	<a href="#cv">CV</a>
	<!--
	<a href="#about">ABOUT</a>
	<a href="#social">twitter, linkedin, git</a> -->
`

appMenu.addEventListener("click", e => {
	log(e.target.tagName)
	if (e.target.tagName === 'A'){
		appMenu.querySelector('.menu-selected').className = ''
    	e.target.className = 'menu-selected'
	}
})


const appElement = document.getElementById('app-placeholder')
let currentModule  // Module showing


const pageMap = new Map([
	['front', frontPage],
	['projects', projectsPage],
	['cv', cvPage],	
])

const moduleMap = new Map([
    ['front', undefined],
	['blog', undefined],
	['projects', undefined],
	['cv', undefined],
	
])


class Page extends UIComponent{
	
	constructor(moduleId) {
        super('div', '.'+moduleId+'-page')
        this.setHTML(pageMap.get(moduleId))
  }
}


function manageModules(moduleId, moduleParams){
	console.log('manageModules',moduleId, moduleParams)

	if (moduleId === '') moduleId = 'front'
	let isPage = pageMap.has(moduleId)
	
	if (moduleMap.has(moduleId)){
        let nextModule = moduleMap.get(moduleId)
		// If the module hasn't been loaded 
		if (!nextModule){// The module is not loaded yet
			nextModule = getModInstance(moduleId, isPage)
			moduleMap.set(moduleId, nextModule)
		}
        if (moduleParams) nextModule.processModParams(moduleParams)
		showModuleElement(nextModule)

	}else{ document.location.hash = ''; return }
}


function getModInstance(moduleId, isPage){
	// console.log('getModInstance', moduleId)
	if (isPage) return new Page(moduleId)
	else if (moduleId === 'blog') return new BlogMod()
}


function showModuleElement(module){
	if (module === currentModule) return
	if (currentModule !== undefined) 
		appElement.removeChild(currentModule.e)
	currentModule = module
	appElement.appendChild(currentModule.e)
}


// URL fragment analysis 
processUrlHash()

window.addEventListener("hashchange", e => {
    processUrlHash()    
})

// Preinit of the blog module
let blogModule = getModInstance('blog', false)
moduleMap.set('blog', blogModule)
blogModule.processModParams('list')


function processUrlHash(){
   let urlHash = document.location.hash.substring(1)
   //console.log('urlHash', /*urlHash.indexOf('?'),*/ urlHash)
   
   if (urlHash.indexOf('?') >= 0){ // Module with params
   		const moduleId = urlHash.substring(0, urlHash.indexOf('?'))
	   const moduleParams = urlHash.substring(urlHash.indexOf('?')+1)
		// console.log('urlHash', urlHash.indexOf('?'), moduleId, moduleParams )
		manageModules(moduleId, moduleParams)
   }else // Module without params, urlHash = moduleId
   	 manageModules(urlHash)
}




/*
document.addEventListener('keydown', e => {
    if (e.code == 'Escape') {
      e.preventDefault()
      ModalPopup.hideModal()
    }
})
*/


